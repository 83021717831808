// i18next-extract-mark-ns-start capabilities-combustion-cf

import { LINKS, COMBUSTION_NAV, CAPABILITIES_PRODUCTS_COMBUSTION_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const CapabilitiesCarbonCombustionPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const gdi = getImage(data.gdi.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Optimising carbon fuel combustion</h2>

						<p>
							Developing and perfecting Internal Combustion Engines (ICE) for conventional hydrocarbon fuels is extremely complex.
							Over decades of engineering evolution, we've honed engine concepts to operate at levels very close to
							optimal performance under most conditions. Improvements, when achieved, are often measured in tiny increments.
							In such precision-driven applications, the accuracy and speed of the modelling toolchain are of paramount importance within
							the Computer Aided Engineering (CAE) process.
						</p>

						<p>
							Chasing these minute differences in performance necessitates the ability to precisely model how combustion systems
							respond to small design changes.
							Simultaneously, the speed of the overall CAE workflow is vital;
							it enables a broader exploration of the design space and facilitates concurrent
							optimisation of systems that were traditionally designed in isolation.
						</p>

						<p>
							In parallel there are still opportunities for paradigm shifts with new technology and novel engine concepts,
							where similarly accuracy and speed of simulation are essential to reduce reliance on expensive hardware prototyping.
						</p>

						<p>
							Realis Simulation's toolchain excels in providing accurate fuel, kinetics, and combustion modelling for all commercially
							available hydrocarbons. Whether it's ethanol-gasoline blends, diesel fuels, or natural gas mixtures,
							Realis Modelling fidelity cascade empowers you to conduct precise analyses of how hydrocarbon combustion impacts various modelling levels.
							This ranges from detailed 3D detailed kinetics simulations and thermal modelling to 1D engine modelling
							and extends to real-time and systems-level evaluations.
						</p>
					</Trans>

					{gdi && (
						<p>
							<GatsbyImage image={gdi} alt="VECTIS gasoline DI combustion" />
							<Trans i18nKey="small"><small>Predicted spray, wall-film formation and combustion in a gasoline DI engine</small></Trans>
						</p>
					)}
				</div>
				<Aside>
					<Aside.Widget
						title={t("_CleanCombustion")}
						className="widget__sibling__pages">
						<AssetList>
							{COMBUSTION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{CAPABILITIES_PRODUCTS_COMBUSTION_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default CapabilitiesCarbonCombustionPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["capabilities-combustion-cf", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/capabilities/combustion/carbon/Realis-Simulation_1344_carbonCombustion_cropped.jpg" }) {
		...imageBreaker
	}
	gdi: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS_Gasoline_DI.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { tags: { in: "Carbon" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 9
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;
